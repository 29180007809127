import { useBreakpointsContext } from '@air/provider-media-query';
import { isNumber } from 'lodash';
import { CSSProperties, memo, MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';

import { VIEW_CONTROLS } from '~/constants/testIDs';
import { SEARCH_STATIC_HEADER_Z_INDEX } from '~/constants/WorkspaceSpacing';

export interface ViewControlsProps {
  header?: ReactNode;
  buttons?: ReactNode;
  leftAdornment?: ReactNode;
  headerRef: MutableRefObject<HTMLDivElement | null>;
  onScrolled?: (isOnTop: boolean) => void;
  scrollRef?: MutableRefObject<HTMLDivElement | null>;
  topPosition?: number;
  style?: CSSProperties;
}

let ticking = false;

export const ViewControls = memo(
  ({ header, onScrolled, buttons, leftAdornment, headerRef, scrollRef, topPosition = 0, style }: ViewControlsProps) => {
    const [_, forceRerender] = useState(0);
    const viewControlsRef = useRef<HTMLDivElement | null>(null);
    const _isScrolledToTopRef = useRef<boolean | undefined>();
    const bottomOfHeader = headerRef?.current ? headerRef.current.clientTop + headerRef.current.clientHeight : 0;
    const { isAboveMediumScreen } = useBreakpointsContext();

    /**
     * On first render, the headerRef.current is undefined, thus bottomOfHeader is too.
     * We want to cause 1 additional render to set the value of headerRef bottomOfHeader
     */
    useEffect(() => {
      if (!bottomOfHeader) {
        forceRerender((s) => s + 1);
      }
    }, [bottomOfHeader]);

    useEffect(() => {
      const scrolledContainer = scrollRef?.current;

      const handleScroll = () => {
        const viewControlsRect = viewControlsRef.current?.getBoundingClientRect();
        const topOfViewControls = viewControlsRect?.top;

        if (isNumber(topOfViewControls) && isNumber(bottomOfHeader)) {
          const isScrolledToTop = topOfViewControls === bottomOfHeader + topPosition;

          /**
           * We only want to trigger a re-render if the value has actually changed,
           * so we store a local copy to compare it against
           */
          if (_isScrolledToTopRef.current !== isScrolledToTop) {
            _isScrolledToTopRef.current = isScrolledToTop;
            onScrolled?.(isScrolledToTop);
          }
        }
      };

      const debouncedScroll = () => {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            handleScroll();
            ticking = false;
          });

          ticking = true;
        }
      };

      !!onScrolled && scrolledContainer?.addEventListener('scroll', debouncedScroll);

      return () => {
        scrolledContainer?.removeEventListener('scroll', debouncedScroll);
      };
    }, [bottomOfHeader, headerRef, _isScrolledToTopRef, onScrolled, scrollRef, topPosition]);

    return (
      <div
        className="border-b border-b-grey-4 bg-grey-1 py-3 transition-all duration-200 ease-out md:pt-0"
        ref={viewControlsRef}
        data-testid={VIEW_CONTROLS}
        onClick={(e) => e.stopPropagation()} // prevent selection of item behind the bar
        style={{
          zIndex: isAboveMediumScreen ? SEARCH_STATIC_HEADER_Z_INDEX : 1,
          position: isAboveMediumScreen && !!bottomOfHeader ? 'sticky' : 'relative',
          // Top position to align headers with open filters, and directly beneath HeaderBar
          top: isAboveMediumScreen ? bottomOfHeader : 0,
          ...style,
        }}
      >
        <div className="flex items-center justify-between">
          {leftAdornment}
          <div className="flex-1">{header}</div>
          {!!buttons && <div className="flex items-center justify-end">{buttons}</div>}
        </div>
      </div>
    );
  },
);

ViewControls.displayName = 'ViewControls';
